.site-header {
    position: relative;
    padding: 24px 0;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 16%;
        width: 84%;
        height: 500px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ4MCIgaGVpZ2h0PSI2NTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICAgIDxwYXRoIGQ9Ik03MzEuMjA3IDY0OS44MDJDOTM1LjQ4NCA2NDIuMTQgMTQ4MCAzMzcuMzI1IDE0ODAgMTgwLjg4OGMwLTE1Ni40MzgtMzA5Ljc0NC0zNi4wNTUtNzIwLTM2LjA1NVMwLTE3NC40ODMgMCAxMzUuMTQ0YzAgMzA5LjYyNyA1MjYuOTMgNTIyLjMyIDczMS4yMDcgNTE0LjY1OHoiIGZpbGw9IiNGNkY4RkEiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
        background-size: 1140px 500px;
        background-repeat: no-repeat;
    }
}

.site-header-inner {
    position: relative; /* To display all elements above the background color */
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-brand {
      display: flex;
    }
}

.header-links {
    display: inline-flex;
    margin-top: 10px;

    li {
        display: inline-flex;
    }

    a:not(.button) {
        @include font-size(9, mobile, true, true, true);
        @if ( get-font-size(9, desktop) != get-font-size(9, mobile) ) {
            @include media( '>medium' ) {
                @include font-size(9, desktop, true, true, true);
            }
        }
        @include font-weight(bold);
        @include anchor-aspect(header);
        line-height: 16px;
        padding: 8px 24px;
    }
}

@include media( '>medium' ) {

    .site-header {
        position: relative;
        padding: 24px 0;

        &::before {
            top: -50%;
            left: 20%;
            width: 80%;
            height: 651px;
            background-size: 1480px 651px;
        }
    }
}
