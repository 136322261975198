.is-boxed {
    background: color(bg, 2);
}

.body-wrap {
    background: color(bg, 1);
    overflow: hidden;
    /* Sticky footer */
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.boxed-container {
    max-width: 1440px;
    margin: 0 auto;
    @include shadow;
}

main {
    flex: 1 0 auto;
}

.section-inner {
    position: relative; /* To always display inner elements above pseudo decorative stuff */
    padding-top: 48px;
    padding-bottom: 48px;
}

@include media( '>medium' ) {

    .section-inner {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.section-entry {
  flex: 80%;
}

.dual-pane-container {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  align-items: stretch;
  flex-wrap: wrap;

  &:first-child {
      margin-top: -8px;
  }

  &:last-child {
      margin-bottom: -8px;
  }
  .dual-pane{
    width:40%;
  }
}

.alert-danger {
  color: #ff6c6c;
  background: #fef0f0;
  padding: 8px 16px;
  border-radius: 4px;
  margin: 5px 0px;
}
