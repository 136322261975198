.features {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 482px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIwNSIgaGVpZ2h0PSI0ODIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICA8cGF0aCBkPSJNODQuMjEyIDQ4MUMzMy4xNTIgNDQ3LjQ3IDAgNDA3Ljg4MSAwIDM2Mi43NDMgMCAyMDQuNTA1IDQ3OC42NzQgMCA2MzYuODQ3IDBjMTU4LjE3NCAwIDMzLjQzNCAxNzIuNzQxIDMzLjQzNCAzMzAuOTggMCA1NS43ODIgNi42NDYgMTA3LjAzNSA4LjMyMyAxNTAuMDJIODQuMjEyeiIgZmlsbD0iI0Y2RjhGQSIvPiAgICA8cGF0aCBkPSJNMTEwNC44MTcgNDgxLjI0MmwtODYxLjQ4NS0uMTdDMjExLjM4MiA0NTMuMjc4IDE5MiA0MjIuMDEzIDE5MiAzODcuNTA0IDE5MiAyMjkuMjggNDc3Ljc5MSA0IDYzNS45NyA0IDc5NC4xNDcgNCAxMjA1IDE0OS4zNTggMTIwNSAzMDcuNTgxYzAgNTkuNDQ1LTQwLjgyMyAxMjAuNTQzLTEwMC4xODMgMTczLjY2MXoiIGZpbGwtb3BhY2l0eT0iLjUiIGZpbGw9IiNGRkYiLz4gIDwvZz48L3N2Zz4=);
        background-size: 1205px 482px;
        background-repeat: no-repeat;
    }
}

.features-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -12px;
    margin-left: -12px;

    &:first-child {
        margin-top: -8px;
    }

    &:last-child {
        margin-bottom: -8px;
    }
}

.feature {
    padding: 8px 12px;
    width: 368px;
    max-width: 344px;
    flex-grow: 1;
}

.feature-inner {
    height: 100%;
}

.feature-icon {
    position: relative;
    display: flex;
    justify-content: center;
    background: color(bg, 1);
    border-radius: 50%;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 80px;
		height: 80px;
        border-radius: 50%;
        @include shadow;
        mix-blend-mode: multiply;
    }

    .icon {
      font-size: 44px;
      margin-top: 20px;
      color: #75ABF3
    }
}

.feature-title {
    margin-top: 20px;
}

@include media( '>medium' ) {

    .features {

        &::before {
            left: 55%;
            width: 45%;
        }
    }

    .features-wrap {

        &:first-child {
            margin-top: -32px;
        }

        &:last-child {
            margin-bottom: -32px;
        }
    }

    .feature {
        padding: 32px 12px;
    }


    .feature-title {
        margin-top: 36px;
    }
}
